import React, { useState, useEffect } from "react";
import { Select, Checkbox, Form, Input, Button, message } from "antd";
import { connect } from "react-redux";
import _ from "lodash";
import axios from "axios";
import check from "../../../assets/img/circled check mark.png";
import { player_positions } from "../../positions";

function CoachInvite(props) {
  const [form] = Form.useForm();
  const [type, setType] = useState(0);
  const [positions, setPositions] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [schoolId, setSchoolId] = useState(null);
  const [step, setStep] = useState(1);

  useEffect(() => {
    const getSchoolIdFromURL = () => {
      const params = new URLSearchParams(window.location.search);
      const schoolIdParam = params.get("team") || "";
      if (schoolIdParam) {
        setSchoolId(schoolIdParam.slice(2, -3));
        console.log("schoolIdParam", schoolId);
      }
    };
    getSchoolIdFromURL();
  }, []);

  const handleInviteCoach = async (values) => {
    setLoading(true);
    try {
      const formData = {
        ...values,
        school: schoolId,
        type: type,
        positions: positions.toString(),
      };
      const response = await axios.post(
        "https://api.wardbord.com/coaches/create",
        formData
      );
      console.log("response ", response);
      if (response?.status !== 200) {
        message.error(response?.data?.error);
        setShow(true);
      } else {
        setStep(2);
        setShow(false);
      }
    } catch (error) {
      console.error("Error registering coach:", error);
      message.error("Registration failed. Please try again.");
    } finally {
      setShow(true);
      setLoading(false);
    }
  };

  return (
    <div className="mt-8 lg:w-[40%] m-auto  pt-20">
      {step === 1 && (
        <>
          <h3
            style={{
              fontSize: "1.7rem",
              lineHeight: "1.2",
              textAlign: "center",
              fontWeight: "800",
            }}
          >
            Join your teammates!
          </h3>
          <div className="text-[12px] mb-8 text-center font-poppins text-[#0F1A24]">
            Complete the Coach intake form with your full name, email, and
            create your password. <br />
            When you're done, you're ready to go
          </div>
          <Form
            form={form}
            layout="vertical"
            onFinish={(e) => handleInviteCoach(e)}
          >
            <div className="flex flex-col justify-center">
              <div className="mt-[10px] flex flex-col">
                <Form.Item
                  name="first_name"
                  label={
                    <span className="mt-[10px] font-inter text-[#2E4D6B] text-[14px] font-normal">
                      First Name:
                    </span>
                  }
                  rules={[
                    { required: true, message: "First name is required" },
                  ]}
                >
                  <Input className="p-2 mt-[5px] text-[#0F1A24] w-full h-[43px] rounded-[4px] border border-[#EAEDF0]" />
                </Form.Item>
                <Form.Item
                  name="last_name"
                  label={
                    <span className="mt-[10px] font-inter text-[#2E4D6B] text-[14px] font-normal">
                      Last Name:
                    </span>
                  }
                  rules={[{ required: true, message: "Last name is required" }]}
                >
                  <Input className="p-2 mt-[5px] text-[#0F1A24] w-full h-[43px] rounded-[4px] border border-[#EAEDF0]" />
                </Form.Item>
                <Form.Item
                  name="email"
                  label={
                    <span className="mt-[10px] font-inter text-[#2E4D6B] text-[14px] font-normal">
                      Email:
                    </span>
                  }
                  rules={[{ required: true, message: "Email is required" }]}
                >
                  <Input
                    type="email"
                    className="p-2 mt-[5px] text-[#0F1A24] w-full h-[43px] rounded-[4px] border border-[#EAEDF0]"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={
                    <span className="mt-[10px] font-inter text-[#2E4D6B] text-[14px] font-normal">
                      Create Password:
                    </span>
                  }
                  rules={[{ required: true, message: "Password is required" }]}
                >
                  <Input.Password className="p-2 mt-[5px] text-[#0F1A24] w-full h-[43px] rounded-[4px] border border-[#EAEDF0]" />
                </Form.Item>
                <Form.Item
                  name="positions"
                  label={
                    <span className="mt-[10px] font-inter text-[#2E4D6B] text-[14px] font-normal">
                      Position:
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      message: "At least one position is required",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Select Position(s)"
                    className="mt-[5px] mb-[10px] text-[#0F1A24] w-full h-[43px] rounded-[4px]"
                    onChange={(positions) => setPositions(positions)}
                    optionLabelProp="label"
                    options={[
                      {
                        label: (
                          <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
                            Defensive unit
                          </span>
                        ),
                        options:
                          player_positions?.defense[0]?.variables?.map(
                            ({ code, name }) => {
                              return {
                                label: (
                                  <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                                    <span className="font-bold">{code}</span>{" "}
                                    {`(${name})`}
                                  </span>
                                ),
                                value: code,
                              };
                            }
                          ) || [],
                      },
                      {
                        label: (
                          <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
                            Offensive unit
                          </span>
                        ),
                        options:
                          player_positions?.offense[0]?.variables?.map(
                            ({ code, name }) => {
                              return {
                                label: (
                                  <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                                    <span className="font-bold">{code}</span>{" "}
                                    {`(${name})`}
                                  </span>
                                ),
                                value: code,
                              };
                            }
                          ) || [],
                      },
                      {
                        label: (
                          <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
                            Special Teams
                          </span>
                        ),
                        options:
                          player_positions?.special_teams[0]?.variables?.map(
                            ({ code, name }) => {
                              return {
                                label: (
                                  <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                                    <span className="font-bold">{code}</span>{" "}
                                    {`(${name})`}
                                  </span>
                                ),
                                value: code,
                              };
                            }
                          ) || [],
                      },
                    ]}
                  />
                </Form.Item>
                <div className="w-full mb-[8px] mt-[10px]">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                    className="w-full h-[43px] rounded-[4px] bg-[#1A91FF] border-1 border-solid border-[#1A91FF]"
                  >
                    <span className="text-[#FFFFFF]">Create</span>
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </>
      )}
      {step == 2 && (
        <div className="w-full flex justify-center pt-20">
          <div className="w-[80%] gap-4 flex text-center flex-col items-center justify-center">
            <img src={check} className="w-[25%]" />
            <span className="text-[#0F1A24] font-bold text-[20px]">
              Registration Successful! <br />
              You are ready to go, kindly {" "}
              <a
                href="https://coach.wardbord.com/login"
                className="text-blue-500 underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                login
              </a>{" "}
                to continue.
              <br />
              <br />
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default CoachInvite;
